<template>
  <div style="padding-bottom: 0.1rem;">
    <!-- !! Filter ------------------------------------------------------------------------------------------------- -->
    <b-card-actions
      ref="cardA"
      no-actions
      no-body
    >
      <b-card class="m-0">
        <b-row>
          <!-- !! Campaign -->
          <b-col cols="12" sm="12" lg="6" xl="3">
            <HeroVisionCampaignSelect
              id="campaign-id"
              v-model="campaignId"
              label="Campaign"
              placeholder="Select Campaign"
              :clearable="false"
              :loading="campaignOptionsLoading"
              :options="campaignOptions"
            />
          </b-col>

          <!-- !! Channel -->
          <!--          <b-col v-if="false" cols="12" sm="12" lg="6" xl="3">-->
          <!--            <HeroVueSelect-->
          <!--              v-if="false"-->
          <!--              id="channel-id"-->
          <!--              v-model="channelId"-->
          <!--              label="Channel"-->
          <!--              placeholder="All Channels"-->
          <!--              :clearable="true"-->
          <!--              :options="channelOptions"-->
          <!--            />-->
          <!--          </b-col>-->

          <!-- !! Date Range -->
          <b-col cols="12" sm="12" lg="6" xl="3">
            <b-form-group
              :label-for="'date-range-picker'"
              :label-cols-md="0"
            >
              <template v-slot:label>
                <span style="font-size: 1rem; font-weight: 600;">
                  Date Range
                </span>
              </template>
              <date-range-picker
                ref="date-range-picker"
                v-model="dateRange"
                style="width: 100%;"
                :locale-data="{ firstDay: 1, format: 'dd/mm/yyyy' }"
                :ranges="rangePresents"
                :opens="'left'"
                :auto-apply="false"
                :show-dropdowns="true"
                :disabled="campaignOptionsLoading"
                v-bind="{
                  ...demoAccount && {
                    minDate: demoDisplayLeadRange.startDate,
                    maxDate: demoDisplayLeadRange.endDate
                  }
                }"
                @update="updateFromDateToDate"
              >
                <template v-slot:input="dates">
                  {{ dates.startDate | dateRangePickerFormat }} - {{ dates.endDate | dateRangePickerFormat }}
                </template>
              </date-range-picker>
            </b-form-group>
          </b-col>

          <!-- !! From Date -->
          <b-col v-if="false" cols="12" sm="6" lg="6" xl="3">
            <HeroInputDate
              id="from-date"
              v-model="fromDate"
              label="From Date"
              :add-calendar="true"
            />
          </b-col>

          <!-- !! To Date -->
          <b-col v-if="false" cols="12" sm="6" lg="6" xl="3">
            <HeroInputDate
              id="to-date"
              v-model="toDate"
              label="To Date"
              :add-calendar="true"
            />
          </b-col>
        </b-row>
      </b-card>
    </b-card-actions>

    <!-- !! Tabs --------------------------------------------------------------------------------------------------- -->
    <b-card-actions
      ref="cardB"
      no-actions
      no-body
    >
      <b-card class="m-0">
        <b-row v-if="campaignId === ''">
          <b-col cols="12" class="vertical-center" style="height: calc(100vh - 320px); overflow: hidden;">
            <h1 class="text-center">
              Please select a campaign
            </h1>
          </b-col>
        </b-row>
        <b-row v-if="campaignId !== ''">
          <b-col cols="12">
            <b-tabs v-model="tabIndex" pills @input="doLoadTabData">
              <Dashboard :data="dataDashboard" />
              <LeadManagement :data="dataLeadManagement" @refresh-data="doLoadTabData('skip-status')" />
              <ClientReports :data="dataClientReport" />
              <SEOClientReports :data="dataSEOClientReport" />
              <TeamMembers :data="dataTeamMember" />
            </b-tabs>
          </b-col>
        </b-row>
      </b-card>
    </b-card-actions>
  </div>
</template>

<script>
import moment from 'moment'
import ErrorService from '@/services/ErrorService'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import HeroInputDate from '@/views/components/form/inputs/HeroInputDate.vue'
import HeroVisionCampaignSelect from '@/views/components/form/selects/HeroVisionCampaignSelect.vue'
import DateRangePicker from 'vue2-daterange-picker'

// Tabs
import Dashboard from '@/views/herovision/Dashboard.vue'
import ClientReports from '@/views/herovision/ClientReports.vue'
import SEOClientReports from '@/views/herovision/components/SEOClientReports.vue'
import LeadManagement from '@/views/herovision/LeadManagement.vue'
import TeamMembers from '@/views/herovision/TeamMembers.vue'
import SweetAlert from '@/services/SweetAlert'

// CSS
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
import axiosInstance from '@/libs/axiosInstance'

export default {
  components: {
    BCardActions,
    HeroInputDate,
    HeroVisionCampaignSelect,
    DateRangePicker,

    // Tabs
    Dashboard,
    ClientReports,
    SEOClientReports,
    LeadManagement,
    TeamMembers,
  },
  filters: {
    dateRangePickerFormat(date) {
      if (!date) return ''

      return moment(date).tz('Asia/Bangkok').format('DD MMMM YYYY')
    },
  },
  data() {
    const today = new Date()
    today.setHours(0, 0, 0, 0)

    const yesterday = new Date()
    yesterday.setDate(today.getDate() - 1)
    yesterday.setHours(0, 0, 0, 0)

    return {
      // Index Variables
      tabIndex: 0,
      campaignId: this.$route.params.campaignId ?? '',
      channelId: '',
      fromDate: moment().tz('Asia/Bangkok').subtract('1', 'month').format('YYYY-MM-DD'),
      toDate: moment().tz('Asia/Bangkok').format('YYYY-MM-DD'),
      // doneGetReportingUrl: false,
      doneGetLastCycleDates: false,

      // Date Picker Variables
      dateRange: {
        startDate: null,
        endDate: null,
      },
      rangePresents: {
        Today: [today, today],
        Yesterday: [yesterday, yesterday],
        'Last 7 Days': [new Date(today.getFullYear(), today.getMonth(), today.getDate() - 6), today],
        'Last 14 Days': [new Date(today.getFullYear(), today.getMonth(), today.getDate() - 13), today],
        'Last 30 Days': [new Date(today.getFullYear(), today.getMonth(), today.getDate() - 29), today],
        'Last 90 Days': [new Date(today.getFullYear(), today.getMonth(), today.getDate() - 89), today],
        'This Month': [new Date(today.getFullYear(), today.getMonth(), 1), new Date(today.getFullYear(), today.getMonth() + 1, 0)],
        'Last Month': [new Date(today.getFullYear(), today.getMonth() - 1, 1), new Date(today.getFullYear(), today.getMonth(), 0)],
        'Last 3 Months': [new Date(today.getFullYear(), today.getMonth() - 2, 1), new Date(today.getFullYear(), today.getMonth() + 1, 0)],
        'Last 6 Months': [new Date(today.getFullYear(), today.getMonth() - 5, 1), new Date(today.getFullYear(), today.getMonth() + 1, 0)],
        'Last 12 Months': [new Date(today.getFullYear(), today.getMonth() - 11, 1), new Date(today.getFullYear(), today.getMonth() + 1, 0)],
      },

      // Dashboard Variables
      dataDashboard: {
        // Loading
        leadPerformanceWithChannelLoading: false,
        analyticPerformanceWithChannelLoading: false,
        totalLeadsOnProcessLoading: false,
        totalLeadsConvertedLoading: false,
        leadsFunnelLoading: false,
        recentLeadsLoading: false,
        leadPerformanceWithAccumulateLoading: false,

        filter: {},
        recentLeads: {
          all: [],
          call: [],
          form: [],
          chatbot: [],
        },
        leadPerformanceWithChannel: {
          totalForm: 0,
          totalCall: {
            answered: 0,
            missed: 0,
          },
          totalDirect: 0,
          totalChatbot: 0,
          channels: [],
        },
        leadPerformanceWithAccumulate: {
          normal: [],
          accumulate: [],
        },
        leadPerformanceWithGraph: [],
        analyticPerformanceWithChannel: [],
        analyticPerformanceWithProduct: [],
        leadsFunnel: {
          insideData: [],
          outsideData: [],
          leftLeads: 0,
        },
        totalLeadsOnProcess: 0,
        totalLeadsConverted: 0,
      },

      // Client Report Variables
      dataClientReport: {
        isLoading: false,
        filter: {},
        clientReport: {
          reportingUrl: '',
          disableTab: false,
        },
      },

      // Lead Management Variables
      dataLeadManagement: {
        leadManagementLoading: false,
        filter: {},
        leadManagement: {},
        status: [],
        referrer: [],
        heroVisionFilter: [],
      },

      // Team Member Variables
      dataTeamMember: {
        filter: {},
        teamMembers: [],
      },

      dataSEOClientReport: {
        reportingUrl: '',
        disableTab: false,
        isLoading: false,
      },

      campaignOptionsLoading: false,
      campaignOptions: [],
      channelOptions: [],
    }
  },
  computed: {
    demoAccount() {
      return this.$store.getters['heroAiAuthentications/loginData'].demo_account
    },

    demoDisplayLeadRange() {
      const {
        demo_display_lead_start_date: demoDisplayLeadStartDate,
        demo_display_lead_end_date: demoDisplayLeadEndDate,
      } = this.$store.getters['heroAiAuthentications/loginData']
      return {
        startDate: demoDisplayLeadStartDate || null,
        endDate: demoDisplayLeadEndDate || null,
      }
    },
  },
  watch: {
    async campaignId(newCampaignId) {
      // this.getReportingUrl()
      // this.getSEOClientReportUrl()

      this.doneGetLastCycleDates = false
      await Promise.all([this.getReportingUrl(), this.getSEOClientReportUrl(), this.getLastCycleDates(newCampaignId)])
      await this.doLoadTabData()

      this.doneGetLastCycleDates = true
    },
    // async channelId() {
    //   this.doneGetReportingUrl = false
    //   await this.doLoadTabData()
    // },
  },
  async mounted() {
    await this.getCampaignOptions()
  },
  methods: {
    // อัพเดตวันที่ FromDate และ ToDate จาก Date Range Picker
    updateFromDateToDate(dates) {
      this.dateRange.startDate = dates.startDate
      this.dateRange.endDate   = dates.endDate

      this.fromDate = moment(dates.startDate).tz('Asia/Bangkok').format('YYYY-MM-DD')
      this.toDate   = moment(dates.endDate).tz('Asia/Bangkok').format('YYYY-MM-DD')

      // this.doneGetReportingUrl = false

      if (this.doneGetLastCycleDates) {
        this.doLoadTabData()
      }
    },

    // โหลดข้อมูลแคมเปญ
    async getCampaignOptions() {
      try {
        this.campaignOptionsLoading = true

        const response = await axiosInstance.get('dropdown-options/herovision-campaign-options?orderByColumn=name&orderByDirection=asc')

        if (response.data.data) {
          this.campaignOptions = response.data.data.map((campaign, index) => ({
            text: this.demoAccount ? `Demo Campaign ${index + 1}` : campaign.name,
            value: campaign.id,
            campaign_status_id: campaign.campaign_status_id,
          }))
          if (this.campaignOptions.length >= 1) {
            this.campaignId = this.campaignOptions[0].value
          }
        }
      } catch (error) {
        this.$swal({ ...SweetAlert.error, html: ErrorService.extractError(error) })
      } finally {
        this.campaignOptionsLoading = false
      }
    },

    // async getChannelOptions() {
    //   try {
    //     const response = await axiosInstance.get(`dropdown-options/channel-options?campaignId=${this.campaignId}&orderByColumn=name&orderByDirection=asc`)
    //
    //     if (response.data.data.data) {
    //       this.channelOptions = response.data.data.data.map(channel => ({
    //         text: channel.name,
    //         value: channel.id,
    //       }))
    //     }
    //   } catch (error) {
    //     this.$swal({ ...SweetAlert.error, html: ErrorService.extractError(error) })
    //   }
    // },

    async getLastCycleDates(campaignId) {
      try {
        const response = await axiosInstance.get(`herovision/get-last-cycle-dates/${campaignId}`)

        if (response.data.data) {
          const dates = {
            startDate: response.data.data.from_date,
            endDate: response.data.data.to_date,
          }

          if (!this.demoAccount) {
            this.updateFromDateToDate(dates)
          } else {
            this.updateFromDateToDate(this.demoDisplayLeadRange)
          }
        }
      } catch (error) {
        this.$swal({ ...SweetAlert.error, html: ErrorService.extractError(error) })
      }
    },

    // โหลดข้อมูลให้แท็บที่เลือกใหม่ เมื่อมีการเปลี่ยนแปลงฟิลเตอร์หรือเปลี่ยนแท็บ
    async doLoadTabData(flag = '') {
      if (this.tabIndex === 1 || this.tabIndex === 4) {
        this.$refs.cardB.showLoading = true
      }

      Object.keys(this.dataDashboard).filter(dataDashboardKey => dataDashboardKey.includes('Loading')).forEach(dataDashboardKey => {
        this.dataDashboard[dataDashboardKey] = true
      })

      const dataFilter = {
        campaignId: this.campaignId,
        channelId: this.channelId,
        fromDate: this.fromDate,
        toDate: this.toDate,
      }

      // if (this.doneGetReportingUrl === false) {
      //   this.dataClientReport.clientReport = await this.getReportingUrl()
      //   await this.getSEOClientReportUrl()
      //   this.doneGetReportingUrl = true
      // }

      // Dashboard
      if (this.tabIndex === 0 && this.campaignId !== '' && this.fromDate !== '' && this.toDate !== '') {
        // ถ้าไม่แยกเป็นสองเงื่อนไข ระบบจะเรียก Backend สองรอบทุกคำสั่ง
        this.dataDashboard.filter = dataFilter

        await this.getLeadPerformanceWithChannel()

        await Promise.all([
          this.getAnalyticPerformanceWithChannel(),
          this.getTotalLeadsOnProcess(),
          this.getTotalLeadsConverted(),
        ])

        await Promise.all([
          this.getLeadsFunnel(),
          this.getRecentLeads(),
          this.getLeadPerformanceWithAccumulate(),
        ])

        this.dataDashboard.leadPerformanceWithGraph       = await this.getLeadPerformanceWithGraph() // unused
        this.dataDashboard.analyticPerformanceWithProduct = await this.getAnalyticPerformanceWithProduct() // unused
      }

      // Lead Management
      if (this.tabIndex === 1 && this.campaignId !== '' && this.fromDate !== '' && this.toDate !== '') {
        this.dataLeadManagement.filter = dataFilter
        this.dataLeadManagement.leadManagementLoading = true

        if (flag !== 'skip-status') {
          this.dataLeadManagement.status = await this.getStatus()
        }

        this.dataLeadManagement.referrer         = await this.getReferrer()
        this.dataLeadManagement.heroVisionFilter = await this.getHeroVisionFilter()
        this.dataLeadManagement.leadManagement   = await this.getLeadManagement()

        this.dataLeadManagement.leadManagementLoading = false
      }

      // Client Report
      if (this.tabIndex === 2 && this.campaignId !== '') {
        this.dataClientReport.filter = dataFilter
      }

      // Team Members
      if (this.tabIndex === 4 && this.campaignId !== '') {
        this.dataTeamMember.filter = dataFilter
        this.dataTeamMember.teamMembers = await this.getTeamMember()
      }

      this.$refs.cardB.showLoading = false
    },

    /**
     * Dashboard Methods
     */

    async getReportingUrl() {
      try {
        this.dataClientReport.isLoading = true
        this.dataClientReport.clientReport.disableTab = false

        const url = `/herovision/client-report/${this.campaignId}/reporting-url`
        const response = await axiosInstance.get(url)

        this.dataClientReport.clientReport = response.data.data
      } catch (error) {
        this.$swal({ ...SweetAlert.error, html: ErrorService.extractError(error) })

        this.dataClientReport.clientReport = {
          reportingUrl: '',
          disableTab: true,
        }
      } finally {
        this.dataClientReport.isLoading = false
      }
    },

    async getSEOClientReportUrl() {
      try {
        this.dataSEOClientReport.isLoading = true
        this.dataSEOClientReport.disableTab = false

        const url = `/herovision/seo-client-report/${this.campaignId}/reporting-url`
        const response = await axiosInstance.get(url)

        this.dataSEOClientReport = { ...response.data.data }
      } catch (error) {
        this.$swal({ ...SweetAlert.error, html: ErrorService.extractError(error) })
      } finally {
        this.dataSEOClientReport.isLoading = false
      }
    },

    async getRecentLeads() {
      try {
        this.dataDashboard.recentLeadsLoading = true

        const url = `/herovision/get-recent-leads/${this.campaignId}/${this.fromDate}/${this.toDate}`
        const response = await axiosInstance.post(url, {})

        this.dataDashboard.recentLeads = response.data.data
      } catch (error) {
        this.$swal({ ...SweetAlert.error, html: ErrorService.extractError(error) })

        this.dataDashboard.recentLeads = {
          all: [],
          call: [],
          form: [],
          chatbot: [],
        }
      } finally {
        this.dataDashboard.recentLeadsLoading = false
      }
    },

    async getLeadPerformanceWithChannel() {
      try {
        this.dataDashboard.leadPerformanceWithChannelLoading = true

        const url = `/herovision/get-lead-performance-with-channel/${this.campaignId}/${this.fromDate}/${this.toDate}`
        const response = await axiosInstance.post(url, {})

        this.dataDashboard.leadPerformanceWithChannel = response.data.data
      } catch (error) {
        this.$swal({ ...SweetAlert.error, html: ErrorService.extractError(error) })

        this.dataDashboard.leadPerformanceWithChannel = {
          totalForm: 0,
          totalCall: {
            answered: 0,
            missed: 0,
          },
          totalDirect: 0,
          totalChatbot: 0,
          channels: [],
        }
      } finally {
        this.dataDashboard.leadPerformanceWithChannelLoading = false
      }
    },

    async getLeadPerformanceWithAccumulate() {
      try {
        this.dataDashboard.leadPerformanceWithAccumulateLoading = true

        const url = `/herovision/get-lead-performance-with-accumulate/${this.campaignId}/${this.fromDate}/${this.toDate}`
        const response = await axiosInstance.post(url, {})

        this.dataDashboard.leadPerformanceWithAccumulate = response.data.data
      } catch (error) {
        this.$swal({ ...SweetAlert.error, html: ErrorService.extractError(error) })

        this.dataDashboard.leadPerformanceWithAccumulate = {
          normal: [],
          accumulate: [],
        }
      } finally {
        this.dataDashboard.leadPerformanceWithAccumulateLoading = false
      }
    },

    async getLeadPerformanceWithGraph() {
      try {
        const url = `/herovision/get-lead-performance-with-graph/${this.campaignId}/${this.fromDate}/${this.toDate}`
        const response = await axiosInstance.post(url, {})

        return response.data.data
      } catch (error) {
        // await this.$swal({ ...SweetAlert.error, html: ErrorService.extractError(error) })
        return []
      }
    },

    async getAnalyticPerformanceWithChannel() {
      try {
        this.dataDashboard.analyticPerformanceWithChannelLoading = true

        const url = `/herovision/get-analytic-performance-with-channel/${this.campaignId}/${this.fromDate}/${this.toDate}`
        const response = await axiosInstance.post(url, {})

        this.dataDashboard.analyticPerformanceWithChannel = response.data.data
      } catch (error) {
        this.$swal({ ...SweetAlert.error, html: ErrorService.extractError(error) })

        this.dataDashboard.analyticPerformanceWithChannel = []
      } finally {
        this.dataDashboard.analyticPerformanceWithChannelLoading = false
      }
    },

    async getAnalyticPerformanceWithProduct() {
      try {
        const url = `/herovision/get-analytic-performance-with-product/${this.campaignId}/${this.fromDate}/${this.toDate}`
        const response = await axiosInstance.post(url, {})

        return response.data.data
      } catch (error) {
        this.$swal({ ...SweetAlert.error, html: ErrorService.extractError(error) })

        return []
      }
    },

    async getLeadsFunnel() {
      try {
        this.dataDashboard.leadsFunnelLoading = true

        const url = `/herovision/get-leads-funnel/${this.campaignId}/${this.fromDate}/${this.toDate}`
        const response = await axiosInstance.post(url, {})

        this.dataDashboard.leadsFunnel = response.data.data
      } catch (error) {
        this.$swal({ ...SweetAlert.error, html: ErrorService.extractError(error) })

        this.dataDashboard.leadsFunnel = {
          insideData: [],
          outsideData: [],
          leftLeads: 0,
        }
      } finally {
        this.dataDashboard.leadsFunnelLoading = false
      }
    },

    async getTotalLeadsOnProcess() {
      try {
        this.dataDashboard.totalLeadsOnProcessLoading = true

        const url = `/herovision/get-total-leads-on-process/${this.campaignId}/${this.fromDate}/${this.toDate}`
        const response = await axiosInstance.post(url, {})

        this.dataDashboard.totalLeadsOnProcess = response.data.data
      } catch (error) {
        this.$swal({ ...SweetAlert.error, html: ErrorService.extractError(error) })

        this.dataDashboard.totalLeadsOnProcess = 0
      } finally {
        this.dataDashboard.totalLeadsOnProcessLoading = false
      }
    },

    async getTotalLeadsConverted() {
      try {
        this.dataDashboard.totalLeadsConvertedLoading = true

        const url = `/herovision/get-total-leads-converted/${this.campaignId}/${this.fromDate}/${this.toDate}`
        const response = await axiosInstance.post(url, {})

        this.dataDashboard.totalLeadsConverted = response.data.data
      } catch (error) {
        this.$swal({ ...SweetAlert.error, html: ErrorService.extractError(error) })

        this.dataDashboard.totalLeadsConverted = 0
      } finally {
        this.dataDashboard.totalLeadsConvertedLoading = false
      }
    },

    /**
     * Lead Management Methods
     */

    async getLeadManagement() {
      let query = ''
      let index = 0

      const currentPage        = this.$store.getters['LeadManagement/getCurrentPage'] ?? 1
      const perPage            = this.$store.getters['LeadManagement/getPerPage'] ?? 25
      const searchText         = this.$store.getters['LeadManagement/getSearchText'] ?? ''
      let filters              = this.$store.getters['LeadManagement/getFilters']
      const status             = this.$store.getters['LeadManagement/getStatus']
      const heroVisionFilterId = this.$store.getters['LeadManagement/getHeroVisionFilter']

      query += '&export_type=excel'
      query += `&page=${currentPage}`
      query += `&perPage=${perPage}`
      query += `&filter_value=${searchText}`
      query += '&types[0]=submitted'
      query += `&filter_id=${heroVisionFilterId}`

      if (filters === undefined) {
        filters = {
          submitted: true,
          answered: true,
          missedCall: true,
          chatbot: true,
        }
      }

      if (filters.submitted) {
        query += `&types[${index}]=submitted`
        index += 1
      }

      if (filters.answered) {
        query += `&types[${index}]=answered`
        index += 1
      }

      if (filters.missedCall) {
        query += `&types[${index}]=missed-call`
        index += 1
      }

      if (filters.chatbot) {
        query += `&types[${index}]=chatbot`
        index += 1
      }

      index = 0
      status.forEach(item => {
        if (item.selected) {
          query += `&status_id[${index}]=${item.id}`
          index += 1
        }
      })

      try {
        const url = `/herovision/lead-management/list/${this.campaignId}?start_date=${this.fromDate} 00:00:00&end_date=${this.toDate} 23:59:59 ${query}`
        const response = await axiosInstance.get(url)

        return response.data.data
      } catch (error) {
        this.$swal({ ...SweetAlert.error, html: ErrorService.extractError(error) })

        return {}
      }
    },

    async getStatus() {
      try {
        const url = `/herovision/lead-management/status/${this.campaignId}`
        const response = await axiosInstance.get(url)

        this.$store.commit('LeadManagement/setStatus', response.data.data)

        return response.data.data
      } catch (error) {
        this.$swal({ ...SweetAlert.error, html: ErrorService.extractError(error) })

        return []
      }
    },

    async getReferrer() {
      try {
        const url = '/herovision/lead-management/referrer/'
        const response = await axiosInstance.get(url)

        return response.data.data
      } catch (error) {
        this.$swal({ ...SweetAlert.error, html: ErrorService.extractError(error) })

        return []
      }
    },

    async getHeroVisionFilter() {
      try {
        const url = `/herovision/lead-management/herovision-filter/${this.campaignId}`
        const response = await axiosInstance.get(url)

        return response.data.data
      } catch (error) {
        this.$swal({ ...SweetAlert.error, html: ErrorService.extractError(error) })

        return []
      }
    },

    /**
     * Team Member Methods
     */

    async getTeamMember() {
      try {
        const url = `/herovision/team-member/${this.campaignId}`
        const response = await axiosInstance.get(url)

        return response.data.data
      } catch (error) {
        this.$swal({ ...SweetAlert.error, html: ErrorService.extractError(error) })

        return 0
      }
    },
  },
}
</script>

<style scoped>
.vertical-center {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
